<template>
  <div class="newAll">
    <div class="alls">
      <div class="newIndexFirst">
        <div class="newIndexFirstLeft">
          <h1>御敌于国门之外，护网于民安自中</h1>
          <p>守护国家安全，护航数字经济</p>
          <p>以"经验-探索-架构-实施"进行构建行业安全逻辑</p>
        </div>
        <div class="newIndexFirstRight">
          <img src="../../../../assets/img/index/1.webp" alt="" />
        </div>
      </div>
      <div class="newIndexThird">
        <h2>核心主旨</h2>
        <div class="newIndexThird1">
          <div class="newIndexThird1Left">
            <img src="../../../../assets/img/index/6.webp" alt="" />
          </div>
          <div class="newIndexThird1Right">
            <p style="margin-top: 0px">
              筑牢网络安全基石，成就网络强国梦想。
              网络社会法治社会，网络空间网警保卫。
            </p>
            <p style="margin-top: 10px">
              树立正确的网络安全观。加强信息基础设施网络安全防护,加强网络安全信息统筹机制,手段、平台建设
            </p>
          </div>
        </div>
        <p>
          加强网络安全事件应急指挥能力建设.积极发展网络安全产业，做到关口前移，防患于未然
        </p>
      </div>
      <div class="newIndexSecond">
        <h2>产品服务</h2>
        <div class="newIndexSecondRight">
          <img src="../../../../assets/img/index/3.webp" alt="" />
        </div>
        <div class="newIndexSecondLeft">
          <div class="newIndexSecondLeft11">
            <div class="newIndexSecondLeft1">
              <h3>御见:网络资产测绘平台</h3>
              <p>
                态势感知：网络空间测绘、威胁情报、蜜网集群、可视化处置、大数据分析
              </p>
            </div>
            <div class="newIndexSecondLeft1">
              <h3>御点:科技漏洞管理平台</h3>
              <p>
                漏洞扫描服务对主机及网站资产提供云端漏洞扫描服务，快速发现网站和主机漏洞风险,防止攻击者通过漏洞植入后门、窃取核心数据、破坏服务器等，帮助企业持续地发现暴露在互联网边界上的常见安全风险。
              </p>
            </div>
            <div class="newIndexSecondLeft1">
              <h3>御炼:CTF竞赛平台</h3>
              <p>
                通过充分参与活动来获得个人的经验，并进行交流和分享，在实践活动中体悟和理解、内化相关知识，然后通过反思、总结，提升为理性知识。
              </p>
            </div>
            <div class="newIndexSecondLeft1">
              <h3>天御:红蓝对抗平台</h3>
              <p>
                近年来，网络攻击日益加剧，网络安全事件多不胜数，国家信息基础设施遭受破坏，在政治、经济上造成严重影响，攻击主体也从个人演进成了组织。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="third"></div>
      <div class="newFourth">
        <h2>校企合作</h2>
        <div class="newFourth1">
          <div class="newFourth1Right">
            <img src="../../../../assets/img/index/9.webp" alt="" />
          </div>
          <div class="newFourth1Left">
            <p style="margin-top: 0px">
              无论您是在校学生还是学校老师,我们十分期待能在贵校开展校企合作,无论是何种合作模式,我们都期待与你的合作
            </p>
            <p>
              依托御之安伴随中国互联网产业发展十多年的安全实践与积累。成就了国内最为完整的企业级安全体系规划、建设、运营及管理的最佳实践，实现了全栈式企业级安全解决方案与安全生态建设，并践行了“云与端之间”的安全管理。
            </p>
          </div>
        </div>
        <p></p>
      </div>
      <div class="eighth">
        <h1>合作案例</h1>
        <div class="eighth1">
          <vue-seamless-scroll
            :data="this.hzList"
            :class-option="classOption"
            class="warp"
          >
            <ul class="ul-item">
              <li
                class="li-item"
                v-for="(item, index) in this.hzList"
                :key="index"
              >
                <img :src="item" alt="" />
              </li>
            </ul>
          </vue-seamless-scroll>
          <vue-seamless-scroll
            :data="this.hzList1"
            :class-option="classOption1"
            class="warp"
          >
            <ul class="ul-item">
              <li
                class="li-item"
                v-for="(item, index) in this.hzList1"
                :key="index"
              >
                <img :src="item" alt="" />
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      activeName: "first",
      activeIndex2: "1",
      wechatShow: false,
      bannerList: [
        require("../../../../assets/img/banner/banner-bg-4.jpg"),
        require("../../../../assets/img/banner/banner-bg-5.jpg"),
        require("../../../../assets/img/banner/footer-bg.jpg"),
      ],
      hzList: [
        require("../../../../assets/img/hzal/partner-5.png"),
        require("../../../../assets/img/hzal/partner-7.webp"),
        require("../../../../assets/img/hzal/partner-9.png"),
      ],
      hzList1: [
        require("../../../../assets/img/hzal/parter1.webp"),
        require("../../../../assets/img/hzal/partner-2.png"),
        require("../../../../assets/img/hzal/partner-3.png"),
        require("../../../../assets/img/hzal/partner-4.png"),
        require("../../../../assets/img/hzal/partner-6.webp"),
        require("../../../../assets/img/hzal/partner-81.jpg"),
      ],
      showNav: true,
      map: null,
      local: null,
      mk: null,
      latitude: "",
      longitude: "",
      keyWords: "",
      popup: false,
      classOption: {
        limitMoveNum: 2,
        direction: 3,
      },
      classOption1: {
        limitMoveNum: 2,
        direction: 2,
      },
    };
  },

  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.scrollLength);
  },
  mounted() {
    window.addEventListener("scroll", this.scrollLength, false);
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
  },
  created() {},
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    scrollLength() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const top = document.documentElement.scrollTop;
      if (top > 122.55) {
        let opacity = top / 100;
        opacity = opacity > 1 ? 1 : opacity;
        this.showOpacity = { opacity };
        this.showNav = false;
      }
      if (top < 100) {
        this.showNav = true;
      }
    },
    aboutUs() {
      this.$router.push("/mb/companyProfile");
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  /*其他*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media only screen and (max-width: 600px) {
  .newAll {
    width: 100%;
    .alls {
      .newIndexFirst {
        width: 100%;
        background-color: #f1f5ff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 40px;
        .newIndexFirstLeft {
          h1 {
            font-size: 18px;
            color: rgb(31, 35, 41);
            margin-bottom: 30px;
          }
          p {
            font-size: 14px;
            margin-top: 10px;
            color: rgb(86, 95, 105);
          }
        }
        .newIndexFirstRight {
          img {
            width:30vw;
            height:31vh;
          }
        }
      }
      .newIndexThird {
        margin-top: 40px;
        padding: 10px 40px;
        p {
          text-indent: 1.5rem;
          font-size: 14px;
          color: #51565d;
          line-height: 20px;
          margin-top: 10px;
        }
        h2 {
          font-size: 16px;
          text-align: center;
          margin-bottom: 20px;
        }
        .newIndexThird1 {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          .newIndexThird1Left {
            margin-right: 20px;
            img {
              width: 30vw;
              height: 22vh;
            }
          }
          .newIndexThird1Right {
            width: 55%;
            p {
              text-indent: 1.5rem;
              font-size: 14px;
              color: #51565d;
              line-height: 20px;
            }
          }
        }
      }
      .newIndexSecond {
        margin-top: 40px;
        background-color: #f1f5ff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 40px;
        .newIndexSecondLeft {
          h2 {
            font-weight: 500;
            font-size: 16px;
            color: #1f2329;
            text-align: center;
          }
          .newIndexSecondLeft11 {
            display: flex;
            flex-direction: column;
            align-items: center;
            .newIndexSecondLeft1 {
              width: 300px;
              border: 3px solid #79797a;
              padding: 10px;
              border-radius: 10px;
              margin: 20px;
              h3 {
                font-size: 14px;
                color: #1f2329;
              }
              p {
                font-size: 14px;
                color: #51565d;
                margin-top: 20px;
                line-height: 26px;
              }
            }
          }
        }
        .newIndexSecondRight {
          img {
            width: 83vw;
            height: 38vh;
          }
        }
      }
      .newFourth {
        margin-top: 40px;
        background-color: #f1f5ff;
        padding: 10px 40px;
        p {
          text-indent: 1.5rem;
          font-size: 14px;
          color: #51565d;
          line-height: 20px;
          margin-top: 10px;
        }
        h2 {
          font-size: 16px;
          color: #1f2329;
          text-align: center;
          margin-bottom: 20px;
        }
        .newFourth1 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .newFourth1Left {
            p {
              text-indent: 1.5rem;
              font-size: 14px;
              color: #51565d;
              margin-top: 10px;
              line-height: 26px;
            }
          }
          .newFourth1Right {
            img {
              width: 87vw;
              height: 40vh;
            }
          }
        }
      }
      .eighth {
        width: 100%;
        padding: 10px 40px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // background-color: #f1f5ff;
        h1 {
          text-align: center;
        }
        .warp {
          width: 400px;
          height: 135px;
          margin: 40px auto;
          overflow: hidden;
          ul {
            list-style: none;
            padding: 0;
            margin: 0 auto;
            // background-color: #f1f5ff;
            &.ul-item {
              display: flex;
              .li-item {
                width: 220px;
                height: 135px;
                margin-right: 50px;
                line-height: 140px;
                color: #fff;
                text-align: center;
                font-size: 1.875rem;
                img {
                  width: 200px;
                  height: 115px;
                }
              }
            }
          }
        }
      }
    }

    .fourth {
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      flex-direction: column;
      h1 {
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 35px;
      }
      .fourth1 {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .box {
          width: 240px;
          margin-top: 20px;
          position: relative;
          transition: all 1s;
          margin-left: 20px;
          transform-style: preserve-3d;
        }
        .front,
        .back {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        //正面
        .front {
          width: 240px;
          height: 200px;
          background-color: #fff;
          z-index: 1;
          backface-visibility: hidden;
          box-shadow: -1px 17px 27px 1px #f0f2f4;
          border-radius: 6px;
          opacity: 1;
          //标题
          .front_title {
            height: 233px;
            display: flex;
            align-items: center;
            justify-content: center;
            ._title_i {
              width: 50px;
              height: 50px;
              margin-right: 18px;
            }
            ._title_p {
              font-size: 30px;
              font-weight: bold;
              color: #515151;
            }
          }
          .front_text {
            position: absolute;
            margin: 0 auto;
            width: 440px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .text_p {
              width: 360px;
              height: 111px;
              font-size: 22px;
              color: #969696;
            }
          }
        }
        //反面
        .back {
          width: 240px;
          height: 200px;
          background: #04040e;
          transform: rotateY(180deg);
          padding: 10px;
          font-size: 16px;
          color: #fff;
          transform-style: preserve-3d;
          border-radius: 8px;
          position: relative;
          //标题
          .back_title {
            height: 174px;
            display: flex;
            align-items: center;
            justify-content: center;
            ._title_itwo {
              font-size: 88px;
              margin-right: 40px;
            }
            ._title_ptwo {
              font-size: 30px;
              font-weight: bold;
              color: #f5f5f5;
              border-radius: 4px;
            }
          }

          //内容
          .back_contentTwo {
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 18px;
            //模板
            .contentTwoIconModules {
              width: 112px;
              height: 101px;
              text-align: center;
              .TwoIconModules_i {
                font-size: 40px;
                color: #f5f5f5;
                margin-bottom: 10px;
              }
              .TwoIconModules_p1 {
                width: 122px;
                height: 21px;
                font-size: 16px;
                color: #f5f5f5;
                margin-bottom: 8px;
              }
              .TwoIconModules_p2 {
                font-size: 14px;
                font-weight: bold;
                color: #f5f5f5;
              }
            }
          }

          //背景图片
          .contentTwoImgModules {
            width: 220px;
            height: 210px;
            position: absolute;
            right: 0%;
            bottom: 0%;
          }
        }
        .box:hover {
          transform: rotateY(180deg);
        }
      }
    }
    .fifth {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 35px;
      }
      .fifth1 {
        width: 100%;
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      .fifth1Left {
        width: 20%;
        img {
          width: 355px;
          height: 600px;
        }
      }
      .fifth1Right {
        width: 60%;
        margin-left: 30px;
        .fifth1Right1 {
          color: #e21f36;
          font-weight: 600;
          padding-bottom: 10px;
          border-bottom: 1px solid #515151;
        }
        h2 {
          font-size: 35px;
          margin-bottom: 40px;
          position: relative;
          font-weight: 600;
        }
        /deep/.el-tabs__item {
          padding: 0 27px;
          height: 40px;
          box-sizing: border-box;
          line-height: 40px;
          display: inline-block;
          list-style: none;
          font-size: 20px;
          font-weight: 500;
          color: #303133;
          position: relative;
        }
        .fifth1Right2 {
          color: #6e727f;
          font-size: 19px;
          margin-top: 10px;
        }
        .fifth1Right3 {
          color: #6e727f;
          font-size: 19px;
          margin-top: 10px;
        }
        .fifth1Right4 {
          color: #6e727f;
          font-size: 19px;
          margin-top: 10px;
        }
      }
    }
    .sixth {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      background-color: #f1f5ff;
      .sixth1 {
        width: 65%;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        .sixth1-1 {
          width: 290px;
          position: relative;
          padding: 20px;
          background-color: #fff;
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          display: flex;
          flex-direction: column;

          h3 {
            font-size: 1.25rem;
            line-height: 1.4;
            text-align: center;
          }
          p {
            text-align: center;
            margin-top: 10px;
            color: #ccc;
            font-size: 1.25rem;
          }
          ul {
            padding-top: 20px;
            font-size: 18px;
            li {
              text-align: center;
              margin-top: 10px;
            }
          }
          .btns {
            margin-top: auto;
            padding-top: 40px;
            text-align: center;
          }
        }
        .sixth1-1:hover {
          color: #fff;
          background-color: #04040e;
          box-shadow: 0 8px 8px 0 rgb(130, 127, 127);
          transform: translate(0, -10px);
          transition: all ease 0.5s;
        }
        .sixth1-2 {
          opacity: 1;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
        }
      }
    }
    .seventh {
      width: 100%;
      margin-top: 40px;
      h1 {
        text-align: center;
      }
      .seventh1 {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }
      .seventh1Left {
        width: 40%;
        .seventh1Left1 {
          margin-top: 40px;
          padding: 10px;
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          h3 {
            margin-bottom: 15px;
            color: #e21f36;
          }
        }
        .seventh1Left1:hover {
          box-shadow: 0 8px 8px 0 rgb(130, 127, 127);
          transform: translate(0, -10px);
          transition: all ease 0.5s;
        }
      }
      .seventh1Right {
        width: 30%;
        img {
          width: 500px;
          height: 645px;
        }
      }
    }

    .form-layer {
      width: 100%;
    }
    #map {
      margin-top: 30px;
      width: 100%;
      height: 300px;
      border: 1px solid gray;
      box-sizing: border-box;
      overflow: hidden;
    }
    /deep/ .el-dialog {
      min-width: 550px;
    }
    /deep/ .el-dialog__body {
      padding: 10px;
    }
  }
  .alls {
    width: 100%;

    .first {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: #f0f0f0;
      .firstLeft {
        width: 10%;
        img {
          width: 55px;
          height: 55px;
        }
      }
      .firstMiddle {
        font-size: 1rem !important;
        // margin-left: 300px;
        /deep/#newElMenu {
          width: 220px !important;
        }
        /deep/.el-menu-item {
          // font-size: 1.25rem;
        }
        /deep/.el-submenu__title {
          padding: 0 20px !important;
          // font-size: 1.25rem;
        }
        /deep/.el-menu
          .el-menu--popup
          .el-menu--popup-bottom-start
          .el-menu-item {
          font-size: 1.5rem !important;
        }
      }
    }
    .newFirst {
      width: 100%;
      display: flex;
      left: 0;
      right: 0;
      top: 0;
      z-index: 99 !important;
      position: fixed;
      background-color: #f0f0f0;
      box-shadow: 0 2px 2px 0 rgb(233, 230, 230);
      justify-content: space-around;
      align-items: center;
      transition: all 0.5s;
      .firstLeft {
        width: 10%;
        img {
          width: 55px;
          height: 55px;
        }
      }
      .firstMiddle {
        font-size: 1rem !important;
        // margin-left: 300px;
        // background-color: #484a4e;
        /deep/#newElMenu {
          width: 220px !important;
        }
        /deep/.el-menu-item {
          // font-size: 1.25rem;
        }
        /deep/.el-submenu__title {
          padding: 0 20px !important;
          // font-size: 1.25rem;
        }
        /deep/.el-menu
          .el-menu--popup
          .el-menu--popup-bottom-start
          .el-menu-item {
          font-size: 1.5rem !important;
        }
      }
    }
    .second {
      width: 100%;
      height: 480px;
      overflow: hidden;
      img {
        width: 100%;
        height: 480px;
      }
    }
    .third {
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      flex-direction: column;
      h1 {
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 35px;
      }
      p {
        margin-bottom: 35px;
        font-size: 22px;
        text-align: center;
      }
      .thirdBtns:hover {
        box-shadow: 0 8px 8px 0 rgb(130, 127, 127);
        transform: translate(0, -10px);
      }
    }
    .fourth {
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      flex-direction: column;
      h1 {
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 35px;
      }
      .fourth1 {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .box {
          width: 240px;
          margin-top: 20px;
          position: relative;
          transition: all 1s;
          margin-left: 20px;
          transform-style: preserve-3d;
        }
        .front,
        .back {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        //正面
        .front {
          width: 240px;
          height: 200px;
          background-color: #fff;
          z-index: 1;
          backface-visibility: hidden;
          box-shadow: -1px 17px 27px 1px #f0f2f4;
          border-radius: 6px;
          opacity: 1;
          //标题
          .front_title {
            height: 233px;
            display: flex;
            align-items: center;
            justify-content: center;
            ._title_i {
              width: 50px;
              height: 50px;
              margin-right: 18px;
            }
            ._title_p {
              font-size: 30px;
              font-weight: bold;
              color: #515151;
            }
          }
          .front_text {
            position: absolute;
            margin: 0 auto;
            width: 440px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .text_p {
              width: 360px;
              height: 111px;
              font-size: 22px;
              color: #969696;
            }
          }
        }
        //反面
        .back {
          width: 240px;
          height: 200px;
          background: #04040e;
          transform: rotateY(180deg);
          padding: 10px;
          font-size: 16px;
          color: #fff;
          transform-style: preserve-3d;
          border-radius: 8px;
          position: relative;
          //标题
          .back_title {
            height: 174px;
            display: flex;
            align-items: center;
            justify-content: center;
            ._title_itwo {
              font-size: 88px;
              margin-right: 40px;
            }
            ._title_ptwo {
              font-size: 30px;
              font-weight: bold;
              color: #f5f5f5;
              border-radius: 4px;
            }
          }

          //内容
          .back_contentTwo {
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 18px;
            //模板
            .contentTwoIconModules {
              width: 112px;
              height: 101px;
              text-align: center;
              .TwoIconModules_i {
                font-size: 40px;
                color: #f5f5f5;
                margin-bottom: 10px;
              }
              .TwoIconModules_p1 {
                width: 122px;
                height: 21px;
                font-size: 16px;
                color: #f5f5f5;
                margin-bottom: 8px;
              }
              .TwoIconModules_p2 {
                font-size: 14px;
                font-weight: bold;
                color: #f5f5f5;
              }
            }
          }

          //背景图片
          .contentTwoImgModules {
            width: 220px;
            height: 210px;
            position: absolute;
            right: 0%;
            bottom: 0%;
          }
        }
        .box:hover {
          transform: rotateY(180deg);
        }
      }
    }
    .fifth {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 35px;
      }
      .fifth1 {
        width: 100%;
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      .fifth1Left {
        width: 20%;
        img {
          width: 355px;
          height: 600px;
        }
      }
      .fifth1Right {
        width: 60%;
        margin-left: 30px;
        .fifth1Right1 {
          color: #e21f36;
          font-weight: 600;
          padding-bottom: 10px;
          border-bottom: 1px solid #515151;
        }
        h2 {
          font-size: 35px;
          margin-bottom: 40px;
          position: relative;
          font-weight: 600;
        }
        /deep/.el-tabs__item {
          padding: 0 27px;
          height: 40px;
          box-sizing: border-box;
          line-height: 40px;
          display: inline-block;
          list-style: none;
          font-size: 20px;
          font-weight: 500;
          color: #303133;
          position: relative;
        }
        .fifth1Right2 {
          color: #6e727f;
          font-size: 19px;
          margin-top: 10px;
        }
        .fifth1Right3 {
          color: #6e727f;
          font-size: 19px;
          margin-top: 10px;
        }
        .fifth1Right4 {
          color: #6e727f;
          font-size: 19px;
          margin-top: 10px;
        }
      }
    }
    .sixth {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .sixth1 {
        width: 65%;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        .sixth1-1 {
          padding: 20px;
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          h3 {
            font-size: 1.25rem;
            line-height: 1.4;
            text-align: center;
          }
          p {
            text-align: center;
            margin-top: 10px;
            color: #ccc;
            font-size: 1.25rem;
          }
          ul {
            padding-top: 20px;
            font-size: 18px;
            li {
              text-align: center;
              margin-top: 10px;
            }
          }
          .btns {
            margin-top: 20px;
            text-align: center;
          }
        }
        .sixth1-1:hover {
          color: #fff;
          background-color: #04040e;
          box-shadow: 0 8px 8px 0 rgb(130, 127, 127);
          transform: translate(0, -10px);
          transition: all ease 0.5s;
        }
      }
    }
    .seventh {
      width: 100%;
      margin-top: 40px;
      h1 {
        text-align: center;
      }
      .seventh1 {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }
      .seventh1Left {
        width: 40%;
        .seventh1Left1 {
          margin-top: 40px;
          padding: 10px;
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          h3 {
            margin-bottom: 15px;
            color: #e21f36;
          }
        }
        .seventh1Left1:hover {
          box-shadow: 0 8px 8px 0 rgb(130, 127, 127);
          transform: translate(0, -10px);
          transition: all ease 0.5s;
        }
      }
      .seventh1Right {
        width: 30%;
        img {
          width: 500px;
          height: 645px;
        }
      }
    }
    .eighth {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        text-align: center;
      }
      .warp {
        width: 326px * 4;
        height: 135px;
        margin: 40px auto;
        overflow: hidden;
        ul {
          list-style: none;
          padding: 0;
          margin: 0 auto;
          &.ul-item {
            display: flex;
            .li-item {
              width: 316px;
              height: 135px;
              margin-right: 50px;
              line-height: 140px;
              color: #fff;
              text-align: center;
              font-size: 1.875rem;
              img {
                width: 288px;
                height: 135px;
              }
            }
          }
        }
      }
    }

    .form-layer {
      width: 100%;
    }
    #map {
      margin-top: 30px;
      width: 100%;
      height: 300px;
      border: 1px solid gray;
      box-sizing: border-box;
      overflow: hidden;
    }
    /deep/ .el-dialog {
      min-width: 550px;
    }
    /deep/ .el-dialog__body {
      padding: 10px;
    }
  }
  .lasts {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #484a4e;
    padding: 10px 10px;
    padding-top: 50px;
    a {
      color: #fff;
    }
    .lastsTop {
      width: 100%;
      display: flex;
      justify-content: center;
      h3 {
        width: 9%;
        font-size: 2rem;
        color: #fff;
      }
      .lastsTop1 {
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 20px;
        margin-top: 30px;
        p {
          margin-top: 5px;
          font-size: 1rem;
          color: #ffffff;
        }
      }
      .lastsTop3 {
        width: 15%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 1%;
        p {
          margin-top: 5px;
          font-size: 1rem;
          color: #ffffff;
        }
      }
      .lastsTop2 {
        width: 7%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 1%;
        p {
          margin-top: 5px;
          font-size: 1rem;
          color: #ffffff;
        }
      }
      .lastsTop6 {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;
        p {
          font-size: 1rem;
          color: #ffffff;
        }
        img {
          width: 5rem;
          height: 5rem;
          margin-top: 5px;
        }
      }
      .lastsTop7 {
        ul {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 0.7rem;
          margin-top: 10px;
          padding-bottom: 10px;
          margin-right: 10px;
          li {
            padding: 0 5px;
            border-right: 1px solid #ccc;
          }
        }
      }
    }
    .lastsMiddle {
      color: #fff;
      border-top: 3px solid #79797a;
      padding-top: 8px;
      text-align: center;
      font-size: 12px;
      a {
        color: #fff;
      }
      span {
        cursor: pointer;
      }
    }
  }
}
</style>